<template>
  <section class="section">
    <div class="columns is-centered">
      <div class="column is-half has-text-centered opacity-box">
        <h1 class="title">Not Found</h1>
        <p>Page not found.</p>
      </div>
    </div>
  </section>
</template>
